<template>
    <section>
        <div class="columns mb-6 bg--light-blue">
            <div class="column is-5">
                <p class="section--tile mb-3">Become an affiliate</p>
                <p class="mb-3">Join our affiliate program and earn up to 20% of every purchase made using your link.</p>
                <b-button type="is-primary" class="mb-5" rounded @click="scrollTo('affiliate-form')">
                    Join now
                </b-button>
            </div>
            <div class="column is-flex is-justify-content-center">
                <img class="affiliate-container--img" src="../assets/affiliate-graph.png" />
            </div>
        </div>
        <p class="section--tile is-flex is-justify-content-center pt-6 mb-6">How does it work?</p>

        <div class="columns mb-6">
            <div class="column">
                <div class="btn-container">
                    <img class="btn-container--img is-flex mb-3" src="../assets/group.svg" />
                    <p class="tag">Join our affiliate program</p>
                    <p class="subtitle">After you join our program you'll receive a promotional code for your audience.</p>
                </div>
            </div>


            <div class="column">
                <div class="btn-container">
                    <img class="btn-container--img is-flex mb-3" src="../assets/send.svg" />
                    <p class="tag">Promote Guiders.pt</p>
                    <p class="subtitle">Tell as many people as possible about us.</p>
                </div>
            </div>

            <div class="column">
                <div class="btn-container">
                    <img class="btn-container--img is-flex mb-3" src="../assets/dashboard--blue-bg.svg" />
                    <p class="tag">See your earnings</p>
                    <p class="subtitle">Check how much money you made and when you'll get paid.</p>
                </div>
            </div>


            <div class="column">
                <div class="btn-container">
                    <img class="btn-container--img is-flex mb-3" src="../assets/credit-card.svg" />
                    <p class="tag">Get paid!</p>
                    <p class="subtitle">Get up to 20% of all the purchases made using your affiliate link. All payments are
                        made automatically your bank account.</p>
                </div>
            </div>

        </div>

        <div class="columns" ref="affiliate-form">
            <div class="column is-4 bg--blue mb-5" :class="{ 'mr-6': !isMobileView }">
                <p class="section--tile">Join our affiliate program</p>
                <AddAffiliateForm class="mb-3" />
            </div>

            <div class="column">
                <p class="section--tile mt-3">Frequently asked questions</p>
                <b-collapse animation="slide" v-for="(collapse, index) of questions" :key="index" :open="isOpen == index"
                    @open="isOpen = questions.length + 1">
                    <template #trigger="props">
                        <div class="card-header" role="button">
                            <p class="card-header-title">
                                {{ collapse.title }}
                            </p>
                            <a class="card-header-icon" v-show="props.open">
                                <b-icon icon="chevron-up"></b-icon>
                            </a>
                            <a class="card-header-icon" v-show="!props.open">
                                <b-icon icon="chevron-down"></b-icon>
                            </a>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span v-html="collapse.text"> {{ collapse.text }}</span>
                        </div>
                    </div>
                </b-collapse>
            </div>

        </div>



    </section>
</template>

<script>
import AddAffiliateForm from '@/Admin/AddAffiliateForm'
import { HelpersMixin } from '../mixins'
export default {
    mixins: [HelpersMixin],
    data() {
        return {
            isOpen: -1,
            questions: [
                {
                    title: "How do I track my earnings?",
                    text: 'You can login to the affiliate dashboard at any time for access to real-time reports and get up-to-the-minute information about any commissions you’ve earned.',
                },

                {
                    title: "How will I get paid",
                    text: "To sign up to our affiliate program you will have to add your bank account information. We will use that to make the payments. You don't have a bank account? That's ok, contact our team at <a class='link' href='mailto:support@guiders.pt'>support@guiders.pt</a> so we can work on an alternative.",
                },
                {
                    title: "Can I join your affiliate program if I am outside Portugal?",
                    text: "Yes, we welcome international affiliates.",
                },
                {
                    title: "Still have questions?",
                    text: "Contact our team at <a class='link' href='mailto:support@guiders.pt'>support@guiders.pt</a>.",
                },
            ],
        }
    },

    components: {
        AddAffiliateForm
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        scrollTo(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop - 90;

            window.scrollTo(
                {
                    top,
                    left: 0,
                    behavior: "smooth",
                }
            );
        }
    }
} 
</script>

<style lang="scss" scoped>
.card-header {
    box-shadow: none;
    background-color: rgba(240, 248, 255, 0.631372549);
    border-radius: 8px;
    margin-bottom: 11px;
}

.card-header-title,
.card-header-icon {
    color: #446DF6;
}

.card-content {
    padding: 14px;
    font-size: .9rem;
}

.columns:has(.is-desktop) {
    display: -webkit-box;
}

.affiliate-container--img {
    max-height: 24rem;
}

.section--tile {
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-family: 'Inter', sans-serif;
    line-height: initial;
}

.bg--light-blue {
    background-color: rgba(240, 248, 255, 0.631372549);
    padding: 2rem 1rem;
}

.bg--blue {
    background-color: #446DF6;
    color: #fff;
    padding: 2rem 1.5rem;
    border-radius: 8px;
    margin: 10px;
}


.btn-container {
    height: 100%;
    padding: 1rem;
    background-color: #fff;
    display: block;
    border-radius: 12px;
    text-align: -webkit-center;
    cursor: auto;
    border: 3px solid rgba(240, 248, 255, 0.631372549);

    .tag {
        width: fit-content;
        border-radius: 50px;
        padding: 0.3rem 0.7rem;
        font-weight: 600;
        background-color: rgba(240, 248, 255, 0.631372549);
        color: #446DF6;
    }

    .subtitle {
        font-size: 1rem;
        margin-top: .5rem;
        font-weight: 400;
    }
}

.columns {
    align-items: flex-start;
}

.button.is-primary {
    height: 2.5rem;
    background-color: #446DF6;
}


::v-deep {


    .input {
        border-color: #0e3d4d;
        border-radius: 50px;
        min-width: 17rem;
    }
}
</style>