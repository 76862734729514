<template>
    <section>
        <div class="widgets">
            <div v-if="isLoading">
                <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
            </div>
            <div v-else>
                <ActivityInProgress />
                <div @click="$emit('toggleVisibility', false)">
                    <router-link :to="{ name: 'company-upcoming-activities' }">
                        <RouteButtonWithIcon title="Upcoming activities" description="See all your scheduled activities.">
                            <template v-slot:icon>
                                <b-icon pack="fas" icon="tasks"></b-icon>
                            </template>
                        </RouteButtonWithIcon>
                    </router-link>
                </div>

                <div @click="goToStripeDashboardClicked" v-if="user.hasFinishedSetUp">
                    <RouteButtonWithIcon title="Stripe Dashboard" description="See all your earning and pending payments.">
                        <template v-slot:icon>
                            <b-icon pack="fab" icon="cc-stripe"></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </div>

                <div @click="$emit('toggleVisibility', false)">
                    <router-link :to="{ name: 'schedule' }">
                        <RouteButtonWithIcon title="Available activities"
                            description="See all the available activities for tomorrow and choose which ones you'd like to work on.">
                            <template v-slot:icon>
                                <b-icon pack="fas" icon="calendar-day"></b-icon>
                            </template>
                        </RouteButtonWithIcon>
                    </router-link>
                </div>
                <div @click="$emit('toggleVisibility', false)">
                    <router-link :to="{ name: 'employees' }">
                        <RouteButtonWithIcon title="Manage employees" description="Add and manage your employees.">
                            <template v-slot:icon>
                                <b-icon pack="fas" icon="users-cog"></b-icon>
                            </template>
                        </RouteButtonWithIcon>
                    </router-link>
                </div>
                <div @click="$emit('toggleVisibility', false)">
                    <router-link :to="{ name: 'company-settings' }">
                        <RouteButtonWithIcon title="Settings" description="">
                            <template v-slot:icon>
                                <b-icon pack="fas" icon="user-cog"></b-icon>
                            </template>
                        </RouteButtonWithIcon>
                    </router-link>
                </div>

                <div @click="signOutUser">
                    <RouteButtonWithIcon title="Sign out" is-danger>
                        <template v-slot:icon>
                            <b-icon pack="fas" icon="sign-out-alt"></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import RouteButtonWithIcon from '../components/elements/RouteButtonWithIcon.vue';
import ActivityInProgress from '../components/elements/ActivityInProgress.vue';
import { GuidersMixin } from "../mixins";
import { mapState } from "vuex";
import { UsersStates } from '../store/Storetypes'
export default {
    data() {
        return {
            isLoading: false
        }
    },
    components: {
        RouteButtonWithIcon,
        ActivityInProgress
    },
    mixins: [GuidersMixin],
    methods: {
        signOutUser() {
            this.$emit('toggleVisibility', false)
            this.$emit('signOut')
        },

        goToStripeDashboardClicked() {
            this.isLoading = true
            this.goToStripeDashboard().then(() => this.isLoading = false)
        }
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
        }),
    }
}
</script>

<style scoped lang="scss">
.column {
    padding: 0 0.75rem;
}

::v-deep {
    .columns.is-mobile {
        display: -webkit-inline-box;
    }
}
</style>