<template>
    <section>
        <div class="widgets">
            <div v-if="isLoading">
                <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
            </div>
            <div v-else>
                <div @click="signOutUser">
                    <RouteButtonWithIcon title="Sign out" is-danger>
                        <template v-slot:icon>
                            <b-icon pack="fas" icon="sign-out-alt"></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import RouteButtonWithIcon from '../components/elements/RouteButtonWithIcon.vue';
import { mapState } from "vuex";
import { UsersStates } from '../store/Storetypes'
export default {
    data() {
        return {
            isLoading: false
        }
    },
    components: {
        RouteButtonWithIcon
    },
    methods: {
        signOutUser() {
            this.$emit('toggleVisibility', false)
            this.$emit('signOut')
        },
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
        }),
    }
}
</script>

<style scoped lang="scss">
.column {
    padding: 0 0.75rem;
}

::v-deep {
    .columns.is-mobile {
        display: -webkit-inline-box;
    }
}
</style>