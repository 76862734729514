import {
    AffiliatesMutations as Mutations,
    AffiliatesStates as States,
    AffiliatesActions as Actions,
    AffiliatesGetters as Getters
} from '../Storetypes'

import { db } from "../../firebase";
import { ToastProgrammatic as toast } from "buefy";

export default {
    state: {
        [States.AFFILIATES]: [],
        [States.SELECTED_AFFILIATE_DATA]: {},
        [States.AFFILIATES_COUPONS]: undefined,
        [States.REFERRAL]: null,
        [States.REFERRAL_EXPIRY]: null
    },

    mutations: {
        [Mutations.SET_AFFILIATES](state, affiliates) {
            state[States.AFFILIATES] = affiliates
        },
        [Mutations.SET_SELECTED_AFFILIATE_DATA](state, affiliate) {
            state[States.SELECTED_AFFILIATE_DATA] = affiliate
        },
        [Mutations.SET_AFFILIATES_COUPONS](state, coupons) {
            state[States.AFFILIATES_COUPONS] = coupons
        },
        [Mutations.SET_REFERRAL](state, payload) {
            // Only set if we don't already have a referral code
            // (to prevent overwriting with null on subsequent navigations)
            if (payload.code || !state[States.REFERRAL]) {
                state[States.REFERRAL] = payload.code
                state[States.REFERRAL_EXPIRY] = payload.expiry || null
                //Send to analytics/backend here
                if (window.analytics) {
                    window.analytics.identify({ referralCode: payload.code })
                }
            }
        },
        [Mutations.RESET_STATES](state) {
            state[States.REFERRAL] = null
            state[States.REFERRAL_EXPIRY] = null
        }
    },
    actions: {
        [Actions.INIT_REFERRAL]({ commit }) {
            // Load from localStorage
            const saved = localStorage.getItem('affiliate_referral')
            if (saved) {
                try {
                    const { code, expiry } = JSON.parse(saved)
                    if (expiry > Date.now()) {
                        commit(Mutations.SET_REFERRAL, { code, expiry })
                    } else {
                        localStorage.removeItem('affiliate_referral')
                    }
                } catch (e) {
                    localStorage.removeItem('affiliate_referral')
                }
            }
        },
        [Actions.CAPTURE_REFERRAL]({ commit, state }, route) {
           const referralCode = route.query.referral?.toString() || null
            // Only update if we got a new referral code
            if (referralCode && referralCode !== state[States.REFERRAL]) {
                // expires ina day
                const expiry = Date.now() + 4 * 60 * 60 * 1000; /* 30 * 24 * 60 * 60 * 1000 */ // 30 days
                commit(Mutations.SET_REFERRAL, { code: referralCode, expiry })
                localStorage.setItem('affiliate_referral', JSON.stringify({
                    code: referralCode,
                    expiry
                }))
                // Optional: Send to analytics
                if (window.analytics) {
                    window.analytics.identify({ referralCode })
                }
            }
        },
        [Actions.CLEAR_REFERRAL]({ commit }) {
            commit(Mutations.RESET_STATES)
            localStorage.removeItem('affiliate_referral')

        },
        [Actions.GET_AFFILIATES]({ commit }) {
            let Affiliates = [];
            db.firestore()
                .collection("affiliates")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        Affiliates.push({
                            ...doc.data(),
                            id: doc.id,
                        });
                    });
                    commit(Mutations.SET_AFFILIATES, Affiliates)
                })
        },
        [Actions.GET_AFFILIATE_DATA]({ state }, affiliateId) {
            const affiliates = state[States.AFFILIATES]
            const selectedAffiliateIndex = affiliates.findIndex((affiliates) => affiliates.id === affiliateId)
            if (selectedAffiliateIndex < 0) return {}
            state[States.SELECTED_AFFILIATE_DATA] = affiliates[selectedAffiliateIndex]
            return affiliates[selectedAffiliateIndex]
        },
        async [Actions.GET_ALL_AFFILIATES_COUPONS]({ commit }) {
            await fetch(`${process.env.VUE_APP_API_URL}/stripe/coupons`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        commit(Mutations.SET_AFFILIATES_COUPONS, data.coupons.data)
                    } else {
                        toast.open({
                            duration: 10000,
                            message: 'There has been an error retrieving the affiliates coupons.',
                            position: "is-bottom",
                            type: "is-danger",
                        });
                    }
                })
                .catch((error) => {
                    toast.open({
                        duration: 10000,
                        message: error,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        }
    },
    getters: {
        [Getters.GET_REFERRAL](state) {
            return state[States.REFERRAL];
        },
        [Getters.GET_REFERRAL_EXPIRY](state) {
            return state[States.REFERRAL_EXPIRY];
        },
        [Getters.HAS_VALID_REFERRAL](state) {
            return state[States.REFERRAL] !== null &&
                (state[States.REFERRAL_EXPIRY] === null || state[States.REFERRAL_EXPIRY] > Date.now())
        },
    }
}