<template>
    <section>
        <div class="widgets">
            <ActivityInProgress />
            <div @click="$emit('toggleVisibility', false)">
                <router-link :to="{ name: 'guider-upcoming-activities' }">
                    <RouteButtonWithIcon title="Upcoming activities" description="See all your scheduled activities.">
                        <template v-slot:icon>
                            <b-icon pack="fas" icon="tasks"></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </router-link>
            </div>
            <div @click="$emit('toggleVisibility', false)">
                <router-link :to="{ name: 'guider-settings' }">
                    <RouteButtonWithIcon title="Settings" description="">
                        <template v-slot:icon>
                            <b-icon pack="fas" icon="user-cog"></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </router-link>
            </div>

            <div @click="signOutUser">
                <RouteButtonWithIcon title="Sign out" is-danger>
                    <template v-slot:icon>
                        <b-icon pack="fas" icon="sign-out-alt"></b-icon>
                    </template>
                </RouteButtonWithIcon>
            </div>

        </div>
    </section>
</template>

<script>
import RouteButtonWithIcon from '../components/elements/RouteButtonWithIcon.vue';
import ActivityInProgress from '../components/elements/ActivityInProgress.vue';
export default {
    components: {
        RouteButtonWithIcon,
        ActivityInProgress
    },
    methods: {
        signOutUser() {
            this.$emit('toggleVisibility', false)
            this.$emit('signOut')
        }
    },
}
</script>

<style scoped lang="scss">
.column {
    padding: 0 0.75rem;
}

::v-deep {
    .columns.is-mobile {
        display: -webkit-inline-box;
    }
}
</style>