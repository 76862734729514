var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"app"},[_c('navbar',{class:{ 'is-invisible navbar--hidden': _vm.hideNavbar },attrs:{"isSignedIn":_vm.isSignedIn}}),_c('router-view',{staticClass:"app-page"})],1),(_vm.loaded)?_c('footer',{staticClass:"footer",class:{ 'is-hidden': _vm.hideFooter }},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._m(0),_vm._m(1),_c('p',[_c('router-link',{staticClass:"option",attrs:{"to":"/concierge"}},[_vm._v("Travel Concierge ")])],1),_c('a',{staticClass:"blue-container",attrs:{"href":"https://www.guiders.pt/guide/registration"}},[_c('p',[_vm._v("Become a guider")]),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"arrow-right"}})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"mb-5"},[_vm._m(2),_c('div',[_c('div',{staticClass:"display-flex mb-2"},[_c('router-link',{staticClass:"option",attrs:{"to":{ name: 'full-day-tours' }}},[_vm._v("Day tours")])],1),_c('div',{staticClass:"display-flex mb-2"},[_c('router-link',{staticClass:"option",attrs:{"to":{ name: 'tuk-tuk-tours' }}},[_vm._v("Tuk Tuk Tours")])],1),_c('div',{staticClass:"display-flex"},[_c('router-link',{staticClass:"option",attrs:{"to":{ name: 'quote' }}},[_vm._v("Customize your trip")])],1)])])]),_vm._m(3),_vm._m(4)]),_vm._m(5)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-4"},[_c('strong',{staticClass:"title is-6 has-text-weight-bold"},[_vm._v("Company")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticClass:"option",attrs:{"href":"https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FAboutUs.pdf?alt=media&token=9bf2d5e0-ec0b-4d4b-9aa0-b2e5943ab1c4","target":"_blank"}},[_vm._v("About us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-4"},[_c('strong',{staticClass:"title is-6 has-text-weight-bold"},[_vm._v("Tour packages")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"mb-4"},[_c('strong',{staticClass:"title is-6 has-text-weight-bold"},[_vm._v("Contact us")])]),_c('div',[_c('div',{staticClass:"display-flex mb-2"},[_c('a',{staticClass:"link mb-1 option",attrs:{"href":"mailto:support@guiders.pt"}},[_vm._v("support@guiders.pt")])]),_c('div',{staticClass:"display-flex"},[_c('a',{staticClass:"link option",attrs:{"href":"https://wa.me/351920043375"}},[_vm._v("(+351) 920 043 375 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('p',{staticClass:"mb-4"},[_c('strong',{staticClass:"title is-6 has-text-weight-bold"},[_vm._v("Payment methods")])]),_c('div',{staticClass:"payment-icons mt-4 mb-5"},[_c('img',{attrs:{"src":require("./assets/Visa.png")}}),_c('img',{attrs:{"src":require("./assets/Mastercard.png")}}),_c('img',{attrs:{"src":require("./assets/UnionPay.png")}}),_c('img',{attrs:{"src":require("./assets/Discover.png")}}),_c('img',{attrs:{"src":require("./assets/DinersClub.png")}}),_c('img',{attrs:{"src":require("./assets/Amex.png")}}),_c('img',{attrs:{"src":require("./assets/JCB.png")}}),_c('img',{attrs:{"src":require("./assets/Bancontact.png")}}),_c('img',{attrs:{"src":require("./assets/GooglePay.png")}}),_c('img',{attrs:{"src":require("./assets/ApplePay.png")}}),_c('img',{attrs:{"src":require("./assets/Alipay.png")}}),_c('img',{attrs:{"src":require("./assets/Giropay.png")}}),_c('img',{attrs:{"src":require("./assets/Ideal.png")}}),_c('img',{attrs:{"src":require("./assets/Klarna.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"green-container mb-2 mt-5"},[_c('div',{staticClass:"is-flex is-align-items-center mb-4"},[_c('a',{staticClass:"option mr-2",attrs:{"href":"https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FContracts.pdf?alt=media&token=adb6898d-d0c6-41bc-be5d-1e49740c99ba","target":"_blank"}},[_vm._v("Contracts")]),_vm._v(" • "),_c('a',{staticClass:"option mr-2 ml-2",attrs:{"href":"https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FPrivacy%20policy.pdf?alt=media&token=e6925517-73ae-4405-bc01-feaeb8e1526d","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(" • "),_c('a',{staticClass:"option mr-2 ml-2",attrs:{"href":"https://www.guiders.pt/frequently-asked-questions"}},[_vm._v("Support")])]),_c('div',{staticClass:"is-flex is-justify-content-space-between mb-2 is-align-items-center"},[_c('a',{attrs:{"href":"https://www.visitportugal.com/pt-pt/content/guiders","target":"_blank"}},[_c('img',{attrs:{"src":require("./assets/turismo_portugal.jpeg")}})]),_c('div',{staticClass:"social--media is-flex is-align-items-center"},[_c('a',{attrs:{"href":"https://www.facebook.com/guiders.pt","target":"_blank"}},[_c('img',{staticClass:"social-icon",attrs:{"src":require("./assets/fb-circle.svg")}})]),_c('a',{attrs:{"href":"https://www.tiktok.com/@guiders.pt","target":"_blank"}},[_c('img',{staticClass:"social-icon",attrs:{"src":require("./assets/tiktok-circle.svg")}})]),_c('a',{attrs:{"href":"https://www.instagram.com/guiders.pt/","target":"_blank"}},[_c('img',{staticClass:"social-icon",attrs:{"src":require("./assets/insta-circle.svg")}})]),_c('a',{attrs:{"href":"https://wa.me/351920043375","target":"_blank"}},[_c('img',{staticClass:"social-icon",attrs:{"src":require("./assets/wp-circle.svg")}})])])]),_vm._v(" @2024 Guiders | RNAAT: 61/2023 ")])
}]

export { render, staticRenderFns }