export const GuidersMutations = {
    SET_GUIDER_UID: 'SET_GUIDER_UID',
    SET_GUIDERS: 'SET_GUIDERS',
    SET_SELECTED_GUIDER: 'SET_SELECTED_GUIDER',
    SET_GUIDER_DATA: 'SET_GUIDER_DATA',
    SET_IS_CREATING_STRIPE_ACCOUNT_LINK:'SET_IS_CREATING_STRIPE_ACCOUNT_LINK',
    SET_IS_USER_GUIDER: 'SET_IS_USER_GUIDER',
    SET_IS_GUEST_GUIDER: 'SET_IS_GUEST_GUIDER',
    UPDATE_ACTIVITY_PARTICIPANTS: 'UPDATE_ACTIVITY_PARTICIPANTS',
    UPDATE_SCANNED_TICKETS: 'UPDATE_SCANNED_TICKETS',
    RESET_ACTIVITY_GROUP_CREATION: 'RESET_ACTIVITY_GROUP_CREATION',
    SET_ACTIVITY_IN_PROGRESS_DATA: 'SET_ACTIVITY_IN_PROGRESS_DATA',
    SET_GUIDER_ACTIVITIES:'SET_GUIDER_ACTIVITIES',
    SET_GUIDER_WAITLISTED_ACTIVITIES: 'SET_GUIDER_WAITLISTED_ACTIVITIES',
    SET_FINISHED_ACTIVITIES: 'SET_FINISHED_ACTIVITIES',
    SET_EMPLOYEE_FINISHED_ACTIVITIES: 'SET_EMPLOYEE_FINISHED_ACTIVITIES'
}

export const GuidersStates = {
    GUIDER_UID: 'GUIDER_UID',
    GUIDERS: 'GUIDERS',
    SELECTED_GUIDER: 'SELECTED_GUIDER',
    IS_USER_A_GUIDER: 'IS_USER_A_GUIDER',
    IS_CREATING_STRIPE_ACCOUNT_LINK:'IS_CREATING_STRIPE_ACCOUNT_LINK',
    GUIDER_DATA: 'GUIDER_DATA',
    IS_GUEST_GUIDER: 'IS_GUEST_GUIDER',
    ACTIVITY_PARTICIPANTS: 'ACTIVITY_PARTICIPANTS',
    SCANNED_TICKETS: 'SCANNED_TICKETS',
    ACTIVITY_IN_PROGRESS_DATA: 'ACTIVITY_IN_PROGRESS_DATA',
    GUIDER_ACTIVITIES: 'GUIDER_ACTIVITIES',
    GUIDER_WAITLISTED_ACTIVITIES: 'GUIDER_WAITLISTED_ACTIVITIES',
    FINISHED_ACTIVITIES: 'FINISHED_ACTIVITIES',
    EMPLOYEE_FINISHED_ACTIVITIES: 'EMPLOYEE_FINISHED_ACTIVITIES'
}

export const GuiderActions = {
    GET_FINISHED_ACTIVITIES: 'GET_FINISHED_ACTIVITIES',
    SET_FINISHED_ACTIVITIES: 'SET_FINISHED_ACTIVITIES',
    PROCESS_GUIDER_PAYMENT:'PROCESS_GUIDER_PAYMENT',
    GET_GUIDERS: 'GET_GUIDERS',
    GET_EMPLOYEE_FINISHED_ACTIVITIES: 'GET_EMPLOYEE_FINISHED_ACTIVITIES',
    GET_WAITLISTED_ACTIVITIES: 'GET_WAITLISTED_ACTIVITIES'
}

export const UserActions = {
    GET_ACTIVITIES: 'GET_ACTIVITIES',
    SIGN_UP: 'SIGN_UP',
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
    AUTHENTICATE_USER: 'AUTHENTICATE_USER',
    RETRIEVE_USER_DATA: 'RETRIEVE_USER_DATA',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    DELETE_USER: 'DELETE_USER',
    SIGN_IN_WITH_GOOGLE:'SIGN_IN_WITH_GOOGLE',
    SIGN_UP_USER: 'SIGN_UP_USER',
    REGISTER_EMPLOYEE: 'REGISTER_EMPLOYEE',
    GET_COMPANY_EMPLOYEES: 'GET_COMPANY_EMPLOYEES',
    REAUTHENTICATE: 'REAUTHENTICATE',
    REGISTER_USER: 'REGISTER_USER',
    SIGN_IN_WITH_LINK: 'SIGN_IN_WITH_LINK',
    SIGN_UP_WITH_LINK: 'SIGN_UP_WITH_LINK',
    GET_EMPLOYEE_DATA: 'GET_EMPLOYEE_DATA',
    GET_VENDOR_DATA: 'GET_VENDOR_DATA',
    SIGN_UP_VENDOR: 'SIGN_UP_VENDOR',
    RESET_STATES: 'RESET_STATES',
    REGISTER_AFFILIATE: 'REGISTER_AFFILIATE',
    LOGIN_AFFILIATE: 'LOGIN_AFFILIATE',
    SIGN_UP_AFFILIATE: 'SIGN_UP_AFFILIATE'
}

export const UsersMutations = {
    RESET_STATES: 'RESET_STATES',
    SET_USER: 'SET_USER',
    SET_AUTHENTICATION_ERROR: 'SET_AUTHENTICATION_ERROR',
    SET_IS_USER_GUIDER: 'SET_IS_USER_GUIDER',
    SET_IS_GUEST_GUIDER: 'SET_IS_GUEST_GUIDER',
    UPDATE_EMPLOYEE_DATA: 'UPDATE_EMPLOYEE_DATA',
    SET_IS_EMPLOYEE: 'SET_IS_EMPLOYEE',
    SET_IS_COMPANY:'SET_IS_COMPANY',
    SET_IS_ADMIN: 'SET_IS_ADMIN',
    SET_IS_VENDOR: 'SET_IS_VENDOR',
    SET_PASSWORD_CHANGED: 'SET_PASSWORD_CHANGED',
    SET_EMPLOYEES: 'SET_EMPLOYEES',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_COOKIES_ACCEPTED: 'SET_COOKIES_ACCEPTED',
    SET_IS_AFFILIATE: 'SET_IS_AFFILIATE'
}

export const UsersStates = {
    USER: 'USER',
    AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
    IS_USER_A_GUIDER: 'IS_USER_A_GUIDER',
    IS_GUEST_GUIDER: 'IS_GUEST_GUIDER',
    IS_EMPLOYEE: 'IS_EMPLOYEE',
    IS_COMPANY: 'IS_COMPANY',
    IS_ADMIN: 'IS_ADMIN',
    IS_VENDOR: 'IS_VENDOR',
    SELECTED_EMPLOYEE_DATA: 'SELECTED_EMPLOYEE_DATA',
    PASSWORD_CHANGED: 'PASSWORD_CHANGED',
    EMPLOYEES: 'EMPLOYEES',
    IS_LOADING: 'IS_LOADING',
    COOKIES_ACCEPTED: 'COOKIES_ACCEPTED',
    VENDOR_DATA: 'VENDOR_DATA',
    IS_AFFILIATE: 'IS_AFFILIATE'
}

export const UsersGetters = {
    GET_USER: 'GET_USER',
    IS_USER_AUTHENTICATED: 'IS_USER_AUTHENTICATED',
    GET_AUTHENTICATION_ERROR: 'GET_AUTHENTICATION_ERROR'
}


export const AffiliatesMutations = {
    SET_AFFILIATES: 'SET_AFFILIATES',
    SET_SELECTED_AFFILIATE_DATA: 'SET_SELECTED_AFFILIATE_DATA',
    SET_AFFILIATES_COUPONS: 'SET_AFFILIATES_COUPONS',
    SET_REFERRAL: 'SET_REFERRAL',
    RESET_STATES:'RESET_STATES'
}

export const AffiliatesStates = {
    AFFILIATES: 'AFFILIATES',
    SELECTED_AFFILIATE_DATA: 'SELECTED_AFFILIATE_DATA',
    AFFILIATES_COUPONS: 'AFFILIATES_COUPONS',
    REFERRAL: 'REFERRAL',
    REFERRAL_EXPIRY: 'REFERRAL_EXPIRY'
}

export const AffiliatesActions = {
    GET_AFFILIATES: 'GET_AFFILIATES',
    GET_AFFILIATE_DATA: 'GET_AFFILIATE_DATA',
    GET_ALL_AFFILIATES_COUPONS: 'GET_ALL_AFFILIATES_COUPONS',
    CAPTURE_REFERRAL: 'CAPTURE_REFERRAL',
    CLEAR_REFERRAL: 'CLEAR_REFERRAL',
    INIT_REFERRAL:'INIT_REFERRAL'
}

export const AffiliatesGetters= {
     GET_REFERRAL: 'GET_REFERRAL',
     GET_REFERRAL_EXPIRY: 'GET_REFERRAL_EXPIRY',
     HAS_VALID_REFERRAL: 'HAS_VALID_REFERRAL'
}
   

export const VendorsMutations = {
    SET_VENDORS: 'SET_VENDORS',
    SET_SELECTED_VENDOR_DATA: 'SET_SELECTED_VENDOR_DATA',
}

export const VendorsStates = {
    VENDORS: 'VENDORS',
    SELECTED_VENDOR_DATA: 'SELECTED_VENDOR_DATA',
}

export const VendorsActions = {
    GET_VENDORS: 'GET_VENDORS',
    GET_VENDOR_DATA: 'GET_VENDOR_DATA',
}

export const NewsletterMutations = {
    SET_EMAILS: 'SET_EMAILS'
}
export const NewsletterStates = {
    SUBSCRIBED_EMAILS: 'SUBSCRIBED_EMAILS',
}

export const NewsletterActions = {
    GET_SUBSCRIBED_EMAILS: 'GET_SUBSCRIBED_EMAILS',
}

export const ActivitiesMutations = {
    SET_ACTIVITIES: 'SET_ACTIVITIES',
    SET_ACTIVITY_GROUPS: 'SET_ACTIVITY_GROUPS',
    SET_SELECTED_ACTIVITY_DATA: 'SET_SELECTED_ACTIVITY_DATA',
    GET_ACTIVITY_DATA: 'GET_ACTIVITY_DATA',
    SET_SELECTED_ACTIVITY_ID: 'SET_SELECTED_ACTIVITY_ID',
    UPDATE_ACTIVITY_DATA: 'UPDATE_ACTIVITY_DATA',
    DELETE_ACTIVITY: 'DELETE_ACTIVITY',
    SET_SELECTED_ACTIVITY_GROUP_DATA: 'SET_SELECTED_ACTIVITY_GROUP_DATA',
    SET_TICKET_DATA: 'SET_TICKET_DATA'
}

export const ActivitiesStates = {
    ACTIVITIES: 'ACTIVITIES',
    TICKET_DATA: 'TICKET_DATA',
    ACTIVITY_GROUPS: 'ACTIVITY_GROUPS',
    SELECTED_ACTIVITY_DATA: 'SELECTED_ACTIVITY_DATA',
    SELECTED_ACTIVITY_ID: 'SELECTED_ACTIVITY_ID',
    SELECTED_ACTIVITY_GROUP_DATA: 'SELECTED_ACTIVITY_GROUP_DATA'
}

export const ActivitiesActions = {
    GET_ACTIVITIES: 'GET_ACTIVITIES',
    GET_ACTIVITY_GROUPS: 'GET_ACTIVITY_GROUPS',
    UPDATE_ACTIVITY_GROUP: 'UPDATE_ACTIVITY_GROUP',
    GET_ACTIVITY_DATA: 'GET_ACTIVITY_DATA',
    GET_PASS_DATA: 'GET_PASS_DATA',
    GET_ACTIVITY_GROUP_DATA: "GET_ACTIVITY_GROUP_DATA",
    GET_ACTIVITY_BY_IDS: 'GET_ACTIVITY_BY_IDS',
    GET_TICKET_DATA: 'GET_TICKET_DATA'
}

export const EventsMutations = {
    SET_EVENTS: 'SET_EVENTS',
    SET_EVENT_GROUPS: 'SET_EVENT_GROUPS',
    SET_SELECTED_EVENT_DATA: 'SET_SELECTED_EVENT_DATA',
    GET_EVENT_DATA: 'GET_EVENT_DATA',
    SET_SELECTED_EVENT_ID: 'SET_SELECTED_EVENT_ID',
    UPDATE_EVENT_DATA: 'UPDATE_EVENT_DATA',
    DELETE_EVENT: 'DELETE_EVENT',
    SET_SELECTED_EVENT_GROUP_DATA: 'SET_SELECTED_EVENT_GROUP_DATA'
}

export const EventsStates = {
    EVENTS: 'EVENTS',
    EVENT_GROUPS: 'EVENT_GROUPS',
    SELECTED_EVENT_DATA: 'SELECTED_EVENT_DATA',
    SELECTED_EVENT_ID: 'SELECTED_EVENT_ID',
    SELECTED_EVENT_GROUP_DATA: 'SELECTED_EVENT_GROUP_DATA'
}

export const EventsActions = {
    GET_EVENTS: 'GET_EVENTS',
    GET_EVENT_GROUPS: 'GET_EVENT_GROUPS',
    UPDATE_EVENT_GROUP: 'UPDATE_EVENT_GROUP',
    GET_EVENT_DATA: 'GET_EVENT_DATA',
    GET_EVENT_GROUP_DATA: "GET_EVENT_GROUP_DATA"
}


export const TicketsActions = {
    GET_TICKETS: 'GET_TICKETS',
    GET_TICKETS_BY_IDS: 'GET_TICKETS_BY_IDS'
}

export const TicketsMutations = {
    SET_SAVED_GROUPS_LIST: 'SET_SAVED_GROUPS_LIST',
    SET_TICKETS: 'SET_TICKETS',
    UPDATE_TICKET_DATA: 'UPDATE_TICKET_DATA',
    DELETE_TICKET: 'DELETE_TICKET',
}

export const TicketsStates = {
    SAVED_GROUPS_LIST: 'SAVED_GROUPS_LIST',
    TICKETS: 'TICKETS',
}
