import {
  UsersMutations as Mutations,
  UsersStates as States,
  UsersGetters as Getters,
  UserActions as Actions
} from '../Storetypes'

import { db } from "../../firebase";
import firebase from "firebase";
import router from "../../router/index";
//import { GuestGuiderId } from '../../enums/Guider'
import { ToastProgrammatic as toast } from 'buefy';
import { BgColors } from './../../enums/BgColors';

const goToRoute = (routeName) => {
  router
    .push({
      name: routeName,
    })
    .catch((err) => {
      console.log(err);
    });
}

const removeUserFromDB = ({ tableName, uid }) => {
  db.firestore()
    .collection(tableName)
    .doc(uid)
    .delete()

}
export default {
  state: {
    [States.USER]: {},
    [States.COOKIES_ACCEPTED]: false,
    [States.EMPLOYEES]: [],
    [States.AUTHENTICATION_ERROR]: null,
    [States.IS_USER_A_GUIDER]: false,
    [States.IS_GUEST_GUIDER]: false,
    [States.IS_EMPLOYEE]: false,
    [States.IS_ADMIN]: false,
    [States.IS_COMPANY]: false,
    [States.IS_VENDOR]: false,
    [States.IS_AFFILIATE]: false,
    [States.SELECTED_EMPLOYEE_DATA]: {},
    [States.VENDOR_DATA]: {},
    [States.PASSWORD_CHANGED]: false,
    [States.IS_LOADING]: null
  },
  mutations: {
    [Mutations.SET_USER](state, userData) {
      state[States.USER] = userData
    },
    [Mutations.SET_COOKIES_ACCEPTED](state, hasAcceptedCookies) {
      state[States.COOKIES_ACCEPTED] = hasAcceptedCookies
    },
    [Mutations.SET_EMPLOYEES](state, employees) {
      state[States.EMPLOYEES] = employees
    },
    [Mutations.SET_IS_LOADING](state, password) {
      state[States.IS_LOADING] = password
    },
    [Mutations.SET_AUTHENTICATION_ERROR](state, error) {
      state[States.AUTHENTICATION_ERROR] = error
    },
    [Mutations.SET_PASSWORD_CHANGED](state, passwordChanged) {
      state[States.PASSWORD_CHANGED] = passwordChanged
    },
    [Mutations.SET_IS_USER_GUIDER](state, isUserAGuider) {
      state[States.IS_USER_A_GUIDER] = isUserAGuider
    },
    [Mutations.SET_IS_EMPLOYEE](state, isEmployee) {
      state[States.IS_EMPLOYEE] = isEmployee
    },
    [Mutations.SET_IS_COMPANY](state, isCompany) {
      state[States.IS_COMPANY] = isCompany
    },
    [Mutations.SET_IS_ADMIN](state, isAdmin) {
      state[States.IS_ADMIN] = isAdmin
    },
    [Mutations.SET_IS_VENDOR](state, isVendor) {
      state[States.IS_VENDOR] = isVendor
    },
    [Mutations.SET_IS_AFFILIATE](state, value) {
      state[States.IS_AFFILIATE] = value
    },
    
    [Mutations.SET_IS_GUEST_GUIDER](state, isGuestGuider) {
      state[States.IS_GUEST_GUIDER] = isGuestGuider
    },
    [Mutations.UPDATE_EMPLOYEE_DATA](state, updatedData) {
      state[States.USER].employees = updatedData
    },
  },
  actions: {
    async [Actions.SIGN_UP]({ commit, dispatch }, payload) {
      await db
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then((user) => {
          var currentUser = db.auth().currentUser;
          // send the signed in user a verification email
          currentUser.sendEmailVerification();
          const bgColor = BgColors[Math.floor(Math.random() * BgColors.length)]

          if (currentUser) {
            currentUser
              .updateProfile({
                displayName: payload.displayName,
                photoURL: `https://api.dicebear.com/5.x/initials/svg?seed=${payload.displayName}?backgroundColor=${bgColor}`,
              })
              .then(() => {
                dispatch(Actions.RESET_STATES)
                dispatch(Actions.SIGN_UP_USER, { user: user.user, isGuider: payload.isGuider })
              });
          }
        })
        .catch((error) => {
          toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
          commit(Mutations.SET_AUTHENTICATION_ERROR, error.message);
        });
    },
    async [Actions.SIGN_IN]({ commit, dispatch }, payload) {
      const { isGuider, email, password, isVendor } = payload
      commit(Mutations.SET_IS_LOADING, true)
      
      await db
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          const { uid } = user.user
          let tableName = null
          if (isVendor) {
            tableName = "vendors"
          } else {
            tableName = isGuider ? 'guides' : 'users'
          }

          dispatch(Actions.RESET_STATES);
          console.log({tableName});
          db.firestore()
            .collection(tableName)
            .where("uid", "==", uid).onSnapshot(docSnapshot => {
              if (!docSnapshot.docChanges().length) {
                const error = 'Invalid email or password.'
                console.log({'no match table': error});
                toast.open({
                  duration: 10000,
                  message: error,
                  position: "is-bottom",
                  type: "is-danger",
                });

                commit(Mutations.SET_IS_LOADING, false)
                commit(Mutations.SET_AUTHENTICATION_ERROR, error);
                return
              }
              docSnapshot.docChanges().forEach((change) => {
                const userData = change.doc.data();
                if (userData.isAccountInactive) {
                  const error = 'Your account has been deactivated by your organization.'
                  toast.open({
                    duration: 10000,
                    message: error,
                    position: "is-bottom",
                    type: "is-danger",
                  });
                  commit(Mutations.SET_IS_LOADING, false)
                  commit(Mutations.SET_AUTHENTICATION_ERROR, error);
                  firebase.auth()
                    .signOut()
                    .catch((error) => {
                      console.log(error);
                    });

                  return
                }
                commit(Mutations.SET_USER, userData)
                if (isVendor) {
                  commit(Mutations.SET_IS_VENDOR, true)
                  return
                }
                if (userData.isAdmin) {
                  console.log({isAdmin: userData.isAdmin});
                  commit(Mutations.SET_IS_ADMIN, true)
                  return
                }
                if (isGuider) {
                  if (userData.companyId) {
                    commit(Mutations.SET_IS_EMPLOYEE, true)
                    return
                  }
                  commit(Mutations.SET_IS_COMPANY, true)
                  // commit(Mutations.SET_IS_GUEST_GUIDER, uid === GuestGuiderId) // TODO- ARE WE STILL DOING THIS??
                  return
                }
              });
            }, err => {
              console.log(`Encountered error: ${err}`);
            });
        })
        .catch((error) => {
          console.log({'no match google': error.message});
          toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
          commit(Mutations.SET_AUTHENTICATION_ERROR, error.message);
        });
    },
    async [Actions.SIGN_UP]({ commit, dispatch }, payload) {
      commit(Mutations.SET_IS_LOADING, true)
      await db
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then((user) => {
          dispatch(Actions.RESET_STATES)
          var currentUser = db.auth().currentUser;
          // send the signed in user a verification email
          currentUser.sendEmailVerification();
          if (currentUser) {
            const bgColor = BgColors[Math.floor(Math.random() * BgColors.length)]
            currentUser
              .updateProfile({
                displayName: payload.displayName,
                photoURL: `https://api.dicebear.com/5.x/initials/svg?seed=${payload.displayName}?backgroundColor=${bgColor}`,
              })
              .then(() => {
                dispatch(Actions.SIGN_UP_USER, { user: user.user, isGuider: payload.isGuider, companyId: payload.companyId })
              }).catch(()=>{
                commit(Mutations.SET_IS_LOADING, false)
              })
          }
        })
        .catch((error) => {
          toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
          commit(Mutations.SET_IS_LOADING, false);
          commit(Mutations.SET_AUTHENTICATION_ERROR, error.message);
        });
    },

    async [Actions.RETRIEVE_USER_DATA]({ commit }, { uid, userData }) {
      if (userData.isAccountInactive) return
      let isGuider = false
      await db.firestore()
        .collection("guides")
        .where("uid", "==", uid).onSnapshot(docSnapshot => {

          docSnapshot.docChanges().forEach((change) => {
            isGuider = true
            commit(Mutations.SET_USER, change.doc.data())
          });
          if (isGuider) return
          commit(Mutations.SET_USER, userData)
        }, err => {
          console.log(`Encountered error: ${err}`);
        });
    },

    /* 
        async [Actions.SIGN_IN_WITH_LINK]({ commit }, payload) {
          await db
            .auth()
            .catch((error) => {
              toast.open({
                duration: 10000,
                message: error.message,
                position: "is-bottom",
                type: "is-danger",
              });
              commit(Mutations.SET_AUTHENTICATION_ERROR, error.message);
            });
        }, */
    async [Actions.SIGN_UP_WITH_LINK]({ commit }, email) {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'https://guiders.pt',
        // This must be true.
        handleCodeInApp: true
      };

      await firebase
        .auth().sendSignInLinkToEmail(email, actionCodeSettings).then(() => {
          // notify and go back to dashboard
        })
        .catch((error) => {
          toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
          commit(Mutations.SET_AUTHENTICATION_ERROR, error.message);
        });
    },
    [Actions.SIGN_IN_WITH_GOOGLE]({ dispatch }, isGuider = false) {
      // const auth =  db.auth();
      const provider = new firebase.auth.GoogleAuthProvider();

      firebase.auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          //var credential = result.credential;

          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = credential.accessToken;
          // The signed-in user info.
          var user = result.user;
          dispatch(Actions.SIGN_UP_USER, { user, isGuider })
        }).catch((error) => {
          toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    [Actions.SIGN_OUT]({ commit, dispatch }) {
      firebase.auth()
        .signOut()
        .then(() => {
          dispatch(Actions.RESET_STATES)
          toast.open({
            duration: 10000,
            message: 'You have been signed out!',
            position: "is-bottom",
          });
          if (router.currentRoute.name !== "home") {
            router
              .push({
                path: "/home",
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((error) => {
          toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
          commit(Mutations.SET_AUTHENTICATION_ERROR, error.message);
        });
    },
    [Actions.RESET_STATES]({ commit }) {
      commit(Mutations.SET_USER, {});
      commit(Mutations.SET_EMPLOYEES, []);
      commit(Mutations.SET_IS_ADMIN, false);
      commit(Mutations.SET_IS_VENDOR, false);
      commit(Mutations.SET_IS_AFFILIATE, false);
      commit(Mutations.SET_IS_GUEST_GUIDER, false);
      commit(Mutations.SET_IS_USER_GUIDER, false);
      commit(Mutations.SET_IS_EMPLOYEE, false);
      commit(Mutations.SET_IS_COMPANY, false);
      commit(Mutations.SET_AUTHENTICATION_ERROR, null);
      commit(Mutations.SET_PASSWORD_CHANGED, false);
      commit(Mutations.SET_IS_LOADING, false);
    },
    [Actions.AUTHENTICATE_USER]({ commit, dispatch }) {
      db.auth().onAuthStateChanged((user) => {
        if (user) {
          const {
            displayName,
            photoURL,
            phoneNumber,
            uid,
            email
          } = user

          // TODO:check to see if we can reduce db calls and still watch for changes
          dispatch(Actions.RETRIEVE_USER_DATA, {
            uid,
            userData: {
              displayName,
              photoURL,
              phoneNumber,
              uid,
              email,
            }
          })

          return true;
        } else {
          commit(Mutations.SET_USER, null)
          return false;
        }
      });
    },
    async [Actions.CHANGE_PASSWORD]({ commit }, { currentPassword, newPassword }) {
      var user = db.auth().currentUser;
      var cred = firebase.auth.EmailAuthProvider.credential(
        user.email, currentPassword);
      user.reauthenticateWithCredential(cred).then(() => {
        const user = db.auth().currentUser
        user.updatePassword(newPassword).then(() => {
          toast.open({
            duration: 10000,
            message: 'Password successfully changed.',
            position: "is-bottom",
            type: "is-success"
          });
          commit(Mutations.SET_PASSWORD_CHANGED, true)
        }).catch((error) => {
          toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
          commit(Mutations.SET_PASSWORD_CHANGED, false)
        });
      }).catch((error) => {
        toast.open({
          duration: 10000,
          message: error.message,
          position: "is-bottom",
          type: "is-danger",
        });
        commit(Mutations.SET_PASSWORD_CHANGED, false)
      });
    },
    [Actions.DELETE_USER]({ commit, state }) {
      const user = db.auth().currentUser
      const isGuider = state[States.IS_USER_A_GUIDER]
      const tableName = isGuider ? 'guides' : 'users'
      const uid = state[States.USER].uid
      user.deleteUser(user).then(() => {

        // TODO: UNCOMMENT IF NEEDED

        /*  if (isGuider) {
            fetch(`${process.env.VUE_APP_API_URL}/stripe/account/delete`, {
             method: "POST",
             body:  state[States.USER].stripeAccountId,
             headers: {
                 "Content-Type": "application/json",
             },
             credentials: "same-origin",
         }) 
             .then((response) => response.json()).then((data) => {
                 if (data.deleted) console.log('stripe account deleted');
             })
             .catch((error) => {
               console.log('stripe NOT account deleted', error);
               toast.open({
                 duration: 10000,
                 message: error,
                 position: "is-bottom",
               });
             });
         } */
        removeUserFromDB({ tableName, uid })
        commit(Mutations.SET_USER, null)
        toast.open({
          duration: 10000,
          message: 'Your account has been deleted.',
          position: "is-bottom",
        });
      }).catch((error) => {
        toast.open({
          duration: 10000,
          message: error.message,
          position: "is-bottom",
          type: "is-danger",
        });
      });

    },
    [Actions.SIGN_UP_USER]({ commit, dispatch }, { user, isGuider, companyId }) {
      const userId = user.uid
      let userData = {
        displayName: user.displayName || user.user.displayName,
        photoURL: user.photoURL || user.user.photoURL,
        //phoneNumber: payload.phoneNumber, // TODO: Discuss if we still need this since we get it from stripe
        email: user.email || user.user.email,
        uid: userId
      };

      if (isGuider) {
        userData.hasFinishedSetUp = false
        commit(Mutations.SET_IS_USER_GUIDER, true);
      }
      if (!companyId && isGuider) {
        commit(Mutations.SET_IS_COMPANY, true);
        dispatch(Actions.REGISTER_USER, { isGuider, userData, userId, companyId })
        return
      }
      userData.registrationFinished = true
      dispatch(Actions.REGISTER_EMPLOYEE, { userData, companyId })
    },
    [Actions.REGISTER_USER]({ commit }, { isGuider, userData, userId, companyId }) {
      const tableName = isGuider ? "guides" : "users"

      const data = {
        userData,
        doc: userId,
        tableName
      }

      fetch(`${process.env.VUE_APP_API_URL}/users/signup`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }).then((response) => {
        if (response.status !== 200) return false
        commit(Mutations.SET_USER, userData)
        commit(Mutations.SET_IS_LOADING, false)
        if (isGuider && !companyId) {
          return true
        }

        if (isGuider && companyId) {
          return true
        }
        // goToRoute('home')
        return true
      }).catch((error) => {
        commit(Mutations.SET_IS_LOADING, false)
        toast.open({
          duration: 10000,
          message: error.message,
          position: "is-bottom",
          type: "is-danger",
        });
        return false
      });
    },

    async [Actions.SIGN_UP_VENDOR]({ commit }, user) {
      console.log({ user });
      commit(Mutations.SET_IS_LOADING, true)
      await db
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password)
        .then(() => {
          var currentUser = db.auth().currentUser;
          // send the signed in user a verification email
          currentUser.sendEmailVerification();
          const bgColor = BgColors[Math.floor(Math.random() * BgColors.length)]

          if (currentUser) {
            currentUser
              .updateProfile({
                isAccountCreated: true,
                displayName: user.displayName,
                photoURL: `https://api.dicebear.com/5.x/initials/svg?seed=${user.displayName}?backgroundColor=${bgColor}`,
              })
              .then(async () => {

                commit(Mutations.SET_IS_VENDOR, true);

                const userData = {
                  ...user,
                  isAccountCreated: true,
                  uid: currentUser.uid,
                  photoURL: `https://api.dicebear.com/5.x/initials/svg?seed=${user.displayName}?backgroundColor=${bgColor}`,
                }

                delete userData.password;

                const docRef = db.firestore().collection("vendors").doc(user.id)
                await docRef.update(userData);
                const updatedDoc = await docRef.get();
                commit(Mutations.SET_USER, updatedDoc.data())
                commit(Mutations.SET_IS_LOADING, false)
              });
          }
        })
        .catch((error) => {
          toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
          commit(Mutations.SET_AUTHENTICATION_ERROR, error.message);
        });
    },

    async [Actions.SIGN_UP_AFFILIATE]({ commit }, user) {
      console.log({ user });
      commit(Mutations.SET_IS_LOADING, true)
      await db
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password)
        .then(() => {
          var currentUser = db.auth().currentUser;
          // send the signed in user a verification email
          currentUser.sendEmailVerification();
          const bgColor = BgColors[Math.floor(Math.random() * BgColors.length)]

          if (currentUser) {
            currentUser
              .updateProfile({
                isStripeAccountSetup: false,
                displayName: user.displayName,
                photoURL: `https://api.dicebear.com/5.x/initials/svg?seed=${user.displayName}?backgroundColor=${bgColor}`,
              })
              .then(async () => {

                commit(Mutations.SET_IS_AFFILIATE, true);

                const userData = {
                  ...user,
                  isStripeAccountSetup: false,
                  uid: currentUser.uid,
                  photoURL: `https://api.dicebear.com/5.x/initials/svg?seed=${user.displayName}?backgroundColor=${bgColor}`,
                }

                delete userData.password;

                const docRef = db.firestore().collection("affiliates").doc(user.stripeAccountId)
                await docRef.set(userData);
                const updatedDoc = await docRef.get();
                commit(Mutations.SET_USER, updatedDoc.data())
                commit(Mutations.SET_IS_LOADING, false)
              });
          }
        })
        .catch((error) => {
          toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
          commit(Mutations.SET_AUTHENTICATION_ERROR, error.message);
        });
    },
    [Actions.REGISTER_EMPLOYEE]({ commit }, { companyId, userData }) {
      const data = { companyId, employeeData: userData }
      commit(Mutations.SET_IS_EMPLOYEE, true)
      fetch(`${process.env.VUE_APP_API_URL}/users/register-employee`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }).then((response) => {
        commit(Mutations.SET_IS_LOADING, false)
        if (response.status !== 200) {
          toast.open({
            duration: 10000,
            message: response.message,
            position: "is-bottom",
            type: "is-danger",
          });
          return false
        }
        commit(Mutations.SET_USER, userData)
        goToRoute('guider-upcoming-activities')
        return true
      }).catch((error) => {
        commit(Mutations.SET_IS_LOADING, false)
        toast.open({
          duration: 10000,
          message: error.message,
          position: "is-bottom",
          type: "is-danger",
        });
        return false
      });
    },
    // todo-  UPDATE??????
    [Actions.GET_EMPLOYEE_DATA]({ state }, employeeId) {
      if (!employeeId) return
      db.firestore()
        .collection("guides").doc(employeeId).get().then((doc) => {
          if (doc.data()) {
            state[States.SELECTED_EMPLOYEE_DATA] = doc.data()
          }
        })
    },
    [Actions.GET_VENDOR_DATA]({ state }, vendorId) {
      if (!vendorId) return
      db.firestore()
        .collection("vendors").doc(vendorId).get().then((doc) => {
          if (doc.data()) {
            state[States.VENDOR_DATA] = doc.data()
          }
        })
    },
    async [Actions.GET_COMPANY_EMPLOYEES]({ commit }, companyId) {
      if (!companyId) return
      let employees = [];
      db.firestore()
        .collection("guides").where('companyId', "==", companyId).onSnapshot((snapshotChange) => {
          snapshotChange.forEach((doc) => {
            employees.push(doc.data());
          });
        });
      commit(Mutations.SET_EMPLOYEES, employees)
    },
    async [Actions.REGISTER_AFFILIATE]({ commit }, { email, password, name }) {
      commit(Mutations.SET_IS_LOADING, true)
      try {
        const { user } = await db.auth().createUserWithEmailAndPassword(email, password); 
        // TODO: Create stripe account and update db
        await db.firestore().collection("affiliates").doc(user.uid).set({ name, email, createdAt: new Date() });
        commit(Mutations.SET_USER, user)
        //commit("SET_USER", user);
      } catch (error) {
        commit(Mutations.SET_AUTHENTICATION_ERROR, error.message);
      } finally {
        commit(Mutations.SET_IS_LOADING, false)
      }
    },

    async [Actions.LOGIN_AFFILIATE]({ commit }, { email, password }) {
      commit(Mutations.SET_IS_LOADING, true)
      try {
        const { user } = await db.auth().signInWithEmailAndPassword(email, password);
        //TODO: find user in db THEN SET USER
        commit(Mutations.SET_USER, user)
      } catch (error) {
        commit(Mutations.SET_AUTHENTICATION_ERROR, error.message);
      } finally {
        commit(Mutations.SET_IS_LOADING, false)
      }
    },
// TODO: REMOVE?
    /* async checkAuth({ commit }) {
      auth.onAuthStateChanged((user) => {
        commit("SET_USER", user || null);
      });
    }, */

  },
  getters: {
    [Getters.GET_USER](state) {
      return state[States.USER];
    },
    [Getters.IS_USER_AUTHENTICATED](state) {
      return !!state[States.USER];
    },
    [Getters.GET_AUTHENTICATION_ERROR](state) {
      return state[States.AUTHENTICATION_ERROR];
    }
  }

}