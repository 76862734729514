import {
    VendorsMutations as Mutations,
    VendorsStates as States,
    VendorsActions as Actions
} from '../Storetypes'

import { db } from "../../firebase";

export default {
    state: {
        [States.VENDORS]: [],
        [States.SELECTED_VENDOR_DATA]: {},
    },

    mutations: {
        [Mutations.SET_VENDORS](state, vendors) {
            state[States.VENDORS] = vendors
        },
        [Mutations.SET_SELECTED_VENDOR_DATA](state, vendor) {
            state[States.SELECTED_VENDOR_DATA] = vendor
        }
    },
    actions: {
        [Actions.GET_VENDORS]({ commit }) {
            let Vendors = [];
            db.firestore()
                .collection("vendors")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        Vendors.push({
                            ...doc.data(),
                            id: doc.id,
                        });
                    });
                    commit(Mutations.SET_VENDORS, Vendors)
                })
        },
        async [Actions.GET_VENDOR_DATA]({ state }, vendorId) {

            const ref = db.firestore()
            .collection("vendors")
            .doc(vendorId)
          const doc = await ref.get();
    
          if (!doc.exists){
            state[States.SELECTED_VENDOR_DATA] = []
            return []
          } 
            state[States.SELECTED_VENDOR_DATA] = doc.data()
            return doc.data()
        },

        
    }
}