export const formattedDate = (date) => {
    return new Date(Number(date)).toLocaleDateString()
}

export const dateWithName = (date) => {
    const formattedDate = new Date(Number(date))
    const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
    };
    return formattedDate.toLocaleDateString("en-US", options);
}

export const dateWithNameFull = (date) => {
    const formattedDate = new Date(Number(date))
    const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
    };
    return formattedDate.toLocaleDateString("en-US", options);
}

export const hasDateTimePassed = (targetTime, targetDate) => {
    const splitTargetTime = targetTime.split(':')
    const targetHour = splitTargetTime[0];
    const targetMinute = splitTargetTime[1];
    let date = new Date(Number(targetDate))
    date = date.setHours(targetHour, targetMinute, '00')
    return new Date(date) > new Date();
}

export const getHourHumanized = (dateString) => {
    const date = new Date(dateString);
    let h = date.getHours();
    let m = date.getMinutes();
    return `${h}:${m}`
}

export const dateWithMonthAndDay = (date) => {
    const formattedDate = new Date(Number(date))
    const options = {
        month: "long",
        day: "numeric",
    };
    return formattedDate.toLocaleDateString("en-US", options);
}

function convertToJsDate(timestamp) {
      // Handle Portuguese date format (e.g., "24 de março de 2025 às 06:23:12 UTC")
      const monthsPT = {
        "janeiro": "January", "fevereiro": "February", "março": "March", "abril": "April",
        "maio": "May", "junho": "June", "julho": "July", "agosto": "August",
        "setembro": "September", "outubro": "October", "novembro": "November", "dezembro": "December"
    };

    // Check if the timestamp is a string before trying to match the regex
    if (typeof timestamp === 'string') {
        const regexPT = /(\d{1,2}) de (\w+) de (\d{4}) às (\d{2}):(\d{2}):(\d{2}) UTC/;
        const matchPT = timestamp.match(regexPT);
        if (matchPT) {
            const [, day, monthPT, year, hours, minutes, seconds] = matchPT;
            const monthEN = monthsPT[monthPT.toLowerCase()];
            if (!monthEN) return 'Unknown';

            // Normalize to ISO format: "YYYY-MM-DDTHH:mm:ssZ"
            const normalizedDate = `${year}-${String(monthEN).padStart(2, '0')}-${String(day).padStart(2, '0')}T${hours}:${minutes}:${seconds}Z`;
            return new Date(normalizedDate);
        }
    }

    // Check if it's a Firestore-like timestamp object
    if (timestamp && typeof timestamp === 'object' && 'seconds' in timestamp && 'nanoseconds' in timestamp) {
        const milliseconds = (parseInt(timestamp.seconds, 10) * 1000) + Math.floor(parseInt(timestamp.nanoseconds, 10) / 1000000);
        return new Date(milliseconds);
    }

    // Check if it's a Unix timestamp (number or numeric string)
    if (!isNaN(timestamp) && timestamp > 0) {
        return new Date(Number(timestamp) * 1000);
    }

    // Check if it's a valid date string (e.g., "2025-03-24T06:23:12.000Z")
    const parsedDate = new Date(timestamp);
    if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
    }

    return 'Unknown';
}


export const formatTimestamp= (timestamp)=> {
    const date = convertToJsDate(timestamp);
    if (date === 'Unknown') return date;

    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day} ${month} ${year} at ${hours}:${minutes}`;
}

export const dateWithDayMonthYear = (date) => {
    const originalDate = new Date(date);

    const day = originalDate.getUTCDate();
    const month = originalDate.getUTCMonth() + 1; // Months are zero-based
    const year = originalDate.getUTCFullYear();

    return `${day}/${month}/${year}`;
}

export const dateTimeToMonthYear = (date) => {
    const originalDate = new Date(date);

    const monthNames = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];

    const month = monthNames[originalDate.getUTCMonth()];
    const day = originalDate.getUTCDate();

    return `${month} ${day}`;
}

export const addDurationToTime = (time, hours = 0, minutes = 0) => {
    // convert the time string to a Date object
    const date = new Date(`1970-01-01T${time}`);

    // add the hours and minutes to the date object
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() + minutes);

    // format the updated time as a string and return it
    const updatedTime = date.toTimeString().substring(0, 5);
    return updatedTime;
}

export const convertMinutesToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60); // get the whole number of hours
    const remainingMinutes = minutes % 60; // get the remaining minutes
    return { hours, minutes: remainingMinutes }
}

export const convertHumanizedTime = (timeHumanized) => {
    const time = timeHumanized.split(':')
    const timeConverted = new Date(new Date().setHours(Number(time[0]), (Number(time[1]))))
    return timeConverted
}

export const getHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    if (hours > 0 && remainingMinutes > 0) {
        return `${hours} ${hours === 1 ? "hour" : "hours"} and ${remainingMinutes} ${remainingMinutes === 1 ? "minute" : "minutes"}`;
    } else if (hours > 0) {
        return `${hours} ${hours === 1 ? "hour" : "hours"}`;
    } else {
        return `${remainingMinutes} ${remainingMinutes === 1 ? "minute" : "minutes"}`;
    }
}

export const dateTodayParsed = () => {
    let dateToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    return dateToday
}

export const hasDatePassed = (date) => {
    let dateToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    const hasDatePassed = Number(date) < Date.parse(dateToday)
    return hasDatePassed
}

export const getTimeDifferenceInMinutes = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const timeDiff = (end.getTime() - start.getTime()) / 60000;
    return timeDiff
}