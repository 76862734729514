export const routesToHideFooterAndNavBar = [
    "my-tours",
    "add-activity",
    "edit-activity",
    "create-event",
    "create-ticket",
    "edit-event",
    "edit-ticket",
    "login",
    "vendor-login",
    "signin-guider",
    "register",
    "register-guider",
    "register-employee",
    "scan-ticket",
    "start-activity",
    "add-employee",
    "manage-employee",
    "quote",
    "company-profile",
    "register-vendor",
    "vendor-ticket-scanner",
    "affiliates-set-up-stripe",
   
]

export const routesToHideFooter= [
    "details",
    "event-details",
    "manage-activities",
    "settings",
    "guider-dashboard",
    "schedule",
    "guider-settings",
    "company-settings",
    "guider-basic-information",
    "settings-basic-information",
    "activity-group-details",
    "guide-settings",
    "admin-dashboard",
    "manage-guiders",
    "manage-vendors",
    'manage-payments',
    "affiliates-program",
    "employees",
    "company-upcoming-activities",
    "company-change-password",
    "guider-change-password",
    "guider-details",
    "vendor-details",
    "guider-upcoming-activities",
    "upcoming-activities",
    "affiliateStripeRefresh",
    "generate-ticket",
    "add-vendor",
    "vendor-dashboard",
    "ticket-details",
    "affiliate-dashboard"
]