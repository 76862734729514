import Vue from "vue";
import Vuex from "vuex";
/* import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters"; */
import guiders from "./modules/guiders";
import activities from "./modules/activities";
import events from "./modules/events";
import users from "./modules/users";
import affiliates from './modules/affiliates'
import tickets from './modules/tickets'
import vendors from './modules/vendors'
import newsletter from "./modules/newsletter";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const initialState = () => {
  return { user: null, error: null };
};

export default new Vuex.Store({
  state: initialState(),
 /*  mutations: mutations,
  actions: actions,
  getters: getters, */
  modules: {
    guiders,
    activities,
    events,
    users,
    affiliates,
    tickets,
    vendors,
    newsletter
  },
  plugins: [createPersistedState()],
});