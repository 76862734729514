<template>
  <section>
    <b-field class="mb-5" :label="isOptionalField('City')">
      <b-autocomplete v-model="city" placeholder="e.g. Lisboa" :data="filteredDataObj" field="city"
        @select="(option) => (selectedCity = option.city)" validation-message="Select a city from the list">
      </b-autocomplete>
    </b-field>

  <!--   <b-field :label="isOptionalField('Meeting point')">
      <b-input v-model="meetingPoint" minlength="5" maxlength="270" type="textarea"
        validation-message="What address should the clients go to?"></b-input>
    </b-field> -->

    <p class="has-text-weight-semibold mb-2">Meeting point: </p>
      <GmapAutocomplete
      @place_changed='setPlace'
    />
    <b-button
      @click='addMarker'
    >
      Add
    </b-button>
    <br/>

    <GmapMap
    class="mb-5 mt-3"
    :center='marker'
    :zoom='18'
    style='width:100%;  height: 350px;'
  >
    <GmapMarker
      :position="marker"
    />
  </GmapMap>

    <b-field class="itinerary" :label="isOptionalField('Itinerary')">
      <div>
        <div class="columns" v-for="(stop, index) in stops" :key="index">
          <div class="column">
            <b-input class="mb-2" v-model="stop.title" placeholder="Stop title" minlength="5"
              validation-message="The stop title has to have at least 5 characters"></b-input>
              <b-input class="mb-2" v-model="stop.imageUrl" placeholder="Image url (optional)"></b-input>
              <b-input class="mb-2" v-model="stop.direction" placeholder="Link to google map direction (optional)"></b-input>
            <b-input v-model="stop.description" placeholder="Describe this stop" minlength="10" maxlength="400"
              type="textarea"
              validation-message="The tour description has to have between 10 and 400 characters"></b-input>
          </div>

          <div class="column is-1">
            <b-button :disabled="index === 0" type="is-danger is-light" icon-left="trash" @click="deleteStop(index)">
            </b-button>
          </div>
        </div>

      </div>
    </b-field>
    
    <b-button type="is-primary" @click="addStop">Add another stop</b-button>


  </section>
</template>

<script>
import CityNames from "../assets/CityNames.js";
import { ActivityFormMixin } from './mixins/ActivityFormMixin'

export default {
  mixins: [ActivityFormMixin],
  props: ["activityInfo"],
  data() {
    return {
      currentPlace: null,
      marker: this.activityInfo.marker ? this.activityInfo.marker:  {
                    lat: 38.71364974975586,
                    lng: -9.138160705566406,
                  },
      meetingPoint: this.activityInfo.meetingPoint,
      city: this.activityInfo.city,
      stops: this.activityInfo.stops,
      selectedCity: this.activityInfo.city,
      CityNamesList: CityNames.cityNames,
    };
  },

  activated() {
    if (this.$route.name === "edit-activity") {
      this.$emit("dataChanged", {
        meetingPoint: this.meetingPoint,
        marker: this.marker,
        city: this.selectedCity,
        stops: this.stops
      });
      return
    }
    this.emitData();
  },

  methods: {
    setDescription(description) {
      this.description = description;
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.marker= marker;
        this.meetingPoint= this.currentPlace.formatted_address;
        this.center = {position: marker};
        this.currentPlace = null;
        this.emitData();
        
        console.log({
          marker: this.marker,
          meetingPoint: this.meetingPoint,
          center: this.center
        });
      }
    },
    emitData() {
      if (this.meetingPoint.length >= 5 && this.selectedCity) {
        this.$emit("dataChanged", {
          meetingPoint: this.meetingPoint,
          city: this.selectedCity,
          marker: this.marker,
          stops: this.stops
        });
      }
    },
    addStop() {
      this.stops.push(
        {
          title: '',
          description: '',
          imageUrl: null,
          direction: null
        }
      )
    },
    deleteStop(indexToDelete) {
      this.stops = this.stops.filter((stop, index) => index !== indexToDelete)
    }
  },

  computed: {
    changeData() {
      const { meetingPoint, selectedCity, stops } = this;
      return {
        meetingPoint,
        selectedCity,
        stops
      };
    },
    filteredDataObj() {
      return this.CityNamesList.filter((option) => {
        return (
          option.city
            .toString()
            .toLowerCase()
            .indexOf(this.city.toLowerCase()) >= 0
        );
      });
    },
  },

  watch: {
    changeData: {
      handler: function () {
        this.emitData();
      },
      deep: true
    },

  },
};
</script>

<style lang="scss" scoped>
.itinerary {
  display: block;
}

.columns {
  display: flex;
  align-items: flex-start;
}

.icon.is-large {
  height: 3rem;
  width: 3rem;
  color: #dbdbdb;
}

::v-deep {
  .upload {
    width: 100%;
    display: block;
  }
}
</style>
